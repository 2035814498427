import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '~/context/ThemeContext';
import themes from '~/utils/themes';
import SEO from '~/components/Seo';
import Cart from '~/components/Cart';
// import { Container } from '~/utils/styles'

const CartPage = () => {
    const { setTheme } = useContext(ThemeContext);

    useEffect(() => setTheme(themes.white), [setTheme]);

    return (
        <>
            <SEO title="Your Shopping Bag" description="" />
            <Cart />
        </>
    );
};

export default CartPage;
