import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const Item = styled.div`
  display: flex;
`;

export const Quantity = styled.div`
  white-space: nowrap;
  display: inline;
`;

export const ColumnQuantity = styled.div`
  width: 40px;
  white-space: pre;
`;

export const ColumnInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`;

export const ColumnPrice = styled.div`
  margin-left: 10px;
`;

export const Title = styled.div`
  text-transform: uppercase;
`;

export const Variant = styled.div`
  display: inline-block;
  text-transform: uppercase;
  //margin-left: 10px;
`;

export const Row = styled.div`
  display: block;
`;

export const SecondRow = styled.div`
  display: flex;
`;

export const RemoveItem = styled.div`
  //margin-left: 40px;
  width: 100px;
  text-transform: uppercase;
`;

export const Price = styled.div`
`;

export const Thumb = styled.div`
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  background-color: #eee;
  margin-right: 20px;
`;

export const ThumbImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
`;
