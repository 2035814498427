import React, {
    useState, useContext, useEffect, useRef, ChangeEvent,
} from 'react';
import { use100vh } from 'react-div-100vh';
import { graphql, useStaticQuery } from 'gatsby';
import { StoreContext } from '~/context/StoreContext';
import { CheckoutButton } from '~/utils/styles';
import CheckBox from '~/components/CheckBox';
import { LineItemInterface } from '~/utils/types';
import Link from '~/components/Link';
import {
    Container,
    Bottom,
    Total,
    TotalTitle,
    TotalPrice,
    // Note,
    Checkout,
    EmptyBag,
    CartThumbCol,
    CartOptionCheckbox,
    CartOptionCheckboxLabel,
    CartOptionCheckboxLabelContent,
    GiftWrapMessageInput,
} from './styles';
import LineItem from './LineItem/LineItem';

const GIFT_MESSAGE_KEY = 'Gift message';

const Cart = () => {
    const height = use100vh();
    const giftWrapInputRef = useRef(null);
    const {
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        store: { client, checkout },
    } = useContext(StoreContext);
    const [updatingGiftWrap, setIsUpdatingGiftWrap] = useState(false);
    const { giftWrapProduct } = useStaticQuery(graphql`
        query {
            giftWrapProduct: shopifyProduct(storefrontId: { eq: "gid://shopify/Product/6850399174690" }) {
                id
                shopifyId
                handle
                variants {
                    title
                    id
                    shopifyId: storefrontId
                    price
                }
            }
        }
    `);

    const [giftWrapProductVariant] = giftWrapProduct?.variants || [];

    const giftWrapInCart = checkout.lineItems?.find(({ variant }) => (
        variant?.product && variant.product.id === giftWrapProduct.shopifyId
    ));

    const filterGiftWrapFromCart = ({ variant }: LineItemInterface) => (
        variant?.product && variant.product.id !== giftWrapProduct.shopifyId
    );

    const cartItems = checkout?.lineItems ? checkout.lineItems.filter(filterGiftWrapFromCart) : [];

    const lineItems = cartItems.map((item) => (
        <LineItem
            key={item?.id?.toString() || ''}
            item={item}
        />
    ));

    const totalPrice = checkout.currencyCode
        ? Intl.NumberFormat(undefined, {
            currency: checkout.currencyCode,
            minimumFractionDigits: 0,
            style: 'currency',
        }).format(checkout?.totalPrice?.amount || 0)
        : null;

    const updateGiftWrapMessage = async (value: string) => {
        if (giftWrapInCart && updateLineItem && checkout.id && giftWrapInCart.id) {
            const customAttributes = [
                {
                    key: GIFT_MESSAGE_KEY,
                    value,
                },
            ];

            await updateLineItem(
                client,
                checkout.id as string,
                giftWrapInCart.id,
                1,
                customAttributes,
            );
        }
    };

    const handleCheckout = async () => {
        if (giftWrapInCart && giftWrapInputRef?.current) {
            const giftWrapInput = giftWrapInputRef.current;
            const { value } = giftWrapInput;
            const [messageAttribute] = giftWrapInCart.customAttributes || [];

            if (value !== messageAttribute?.value) {
                await updateGiftWrapMessage(value);
            }
        }

        if (checkout?.webUrl) {
            window.open(checkout.webUrl);
        }
    };

    const addGiftWrap = async () => {
        const { shopifyId } = giftWrapProductVariant || {};

        if (shopifyId && addVariantToCart) {
            setIsUpdatingGiftWrap(true);

            await addVariantToCart(shopifyId, 1, []);

            setIsUpdatingGiftWrap(false);
        }
    };

    const removeFromCart = async (lineItem: LineItemInterface) => {
        if (lineItem?.id && removeLineItem) {
            setIsUpdatingGiftWrap(true);

            await removeLineItem(client, checkout.id as string, lineItem.id);

            setIsUpdatingGiftWrap(false);
        }
    };

    const handleGiftWrapChange = (e: ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        const isChecked = target.checked;

        if (isChecked && !giftWrapInCart?.id) {
            // setShouldFocusMessageInput(true);
            addGiftWrap();
        } else if (!isChecked && giftWrapInCart) {
            removeFromCart(giftWrapInCart);
        }
    };

    // const handleGiftMessageChange = (e) => {
    //     updateGiftWrapMessage(e.target.value || '');
    // };

    useEffect(() => {
        if (cartItems.length === 0 && giftWrapInCart?.id) {
            removeFromCart(giftWrapInCart);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkout.lineItems, giftWrapInCart]);

    const giftWrapPrice = Intl.NumberFormat(undefined, {
        currency: checkout?.currencyCode || 'EUR',
        minimumFractionDigits: 0,
        style: 'currency',
    }).format(giftWrapProductVariant.price);

    const giftWrapInCartAttributes = giftWrapInCart?.customAttributes;

    const giftWrapInCartMessage = giftWrapInCartAttributes?.find(({ key }) => (
        key === GIFT_MESSAGE_KEY
    ))?.value || '';

    return (
        <>
            {
                cartItems.length > 0
                    ? (
                        <Container height={height}>
                            <div>
                                {lineItems}
                            </div>

                            {giftWrapProduct && giftWrapProductVariant && (
                                <CartOptionCheckbox>
                                    <CartOptionCheckboxLabel
                                        htmlFor="cartGiftWrapInput"
                                    >
                                        <CartThumbCol>
                                            <CheckBox
                                                id="cartGiftWrapInput"
                                                name="Giftwrap"
                                                disabled={updatingGiftWrap}
                                                checked={!!giftWrapInCart}
                                                onChange={handleGiftWrapChange}
                                            />
                                        </CartThumbCol>

                                        <CartOptionCheckboxLabelContent>
                                            <span>Add gift wrapping</span>
                                            <span>{giftWrapPrice}</span>
                                        </CartOptionCheckboxLabelContent>
                                    </CartOptionCheckboxLabel>

                                    {giftWrapInCart?.id && (
                                        <GiftWrapMessageInput
                                            ref={giftWrapInputRef}
                                            maxLength={200}
                                            defaultValue={giftWrapInCartMessage}
                                            placeholder="Gift message (free)"
                                            // onChange={handleGiftMessageChange}
                                            // onBlur={handleGiftMessageChange}
                                            // autoFocus={shouldFocusMessageInput}
                                        />
                                    )}
                                </CartOptionCheckbox>
                            )}

                            <Total>
                                <TotalTitle>Subtotal</TotalTitle>
                                <TotalPrice>{totalPrice}</TotalPrice>
                            </Total>

                            {/* {
                                (selectedVariations.includes('Custom')
                                    || selectedVariations.includes('custom')) &&
                                <Note>
                                    Note: This order contains made to measure items. Send an
                                    email to <a href="mailto:info@slic.world">info@slic.world</a>
                                    with your preferred chain length (in cm) after completing
                                    your purchase.
                                </Note>
                            } */}

                            <Bottom>
                                <Checkout>
                                    <CheckoutButton
                                        onClick={handleCheckout}
                                        disabled={checkout.lineItems?.length === 0}
                                    >
                                        Checkout
                                    </CheckoutButton>
                                </Checkout>
                            </Bottom>
                        </Container>
                    )
                    : (
                        <EmptyBag height={height}>
                            <div>
                                Your bag is currently empty. Continue browsing
                                {' '}
                                <Link to="/">here</Link>
                                .
                            </div>
                        </EmptyBag>
                    )
            }
        </>
    );
};

export default Cart;
