import styled from '@emotion/styled';
import { breakpoints } from '~/utils/styles';

export const Container = styled.div(({ height }: { height: number | null }) => `
  min-height: ${height ? `calc(${height}px - 49px)` : 'calc(100vh - 49px)'};

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  margin: 0 auto;
  padding: 105px 27px 27px;

  @media ${breakpoints.lg} { 
    min-height: calc(100vh - 67px);
    max-width: 400px;
    justify-content: center;

    padding: 100px 0 170px;
  }
`);

export const Bottom = styled.div`
  display: flex;
  // margin-top: auto;
`;

export const Total = styled.div`
  display: flex;
  text-transform: uppercase;

  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
  
  padding: 18px 0 15px;
  line-height: 1;
`;

export const TotalTitle = styled.div`
  width: 120px;
`;

export const TotalPrice = styled.div`
  margin-left: auto;
`;

export const Note = styled.div`
  margin-top: 20px;
  text-transform: uppercase;

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: rgba(0, 0, 0, 0.3);
    }
  }
`;

export const Checkout = styled.div`
  width: 100%;
`;

export const EmptyBag = styled.div(({ height }: { height: number | null }) => `
  min-height: ${height ? `${height}px` : '100vh'};

  display: flex;
  //flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: -54px;
  padding: 0 27px;

  text-transform: uppercase;

  @media ${breakpoints.lg} { 
    min-height: 100vh;
    margin-top: -72px;
    padding: 0 27px;
  }
`);

export const CartThumbCol = styled.div`
  width: 70px;
  flex-shrink: 0;
  margin-right: 20px;
`;

export const CartOptionCheckbox = styled.div`
  margin: 13px 0 38px;
`;

export const CartOptionCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
`;

export const CartOptionCheckboxLabelContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const GiftWrapMessageInput = styled.textarea`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 35px;
  border: 2px solid;
  box-sizing: border-box;
  font-size: inherit;
  line-height: inherit;
  margin-top: 25px;
  font-family: inherit;
  padding: 10px;
  border-radius: 0;

  &::placeholder {
    color: inherit;
    text-transform: uppercase;
  }

  &:focus::placeholder {
    opacity: 0;
  }
`;
