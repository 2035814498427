import React, { useContext } from 'react';
import { StoreContext } from '~/context/StoreContext';
import { CustomAttributeInterface, LineItemInterface, OptionInterface } from '~/utils/types';
import {
    Button,
} from '~/utils/styles';
import {
    Wrapper,
    Item,
    // ColumnQuantity,
    ColumnInformation,
    ColumnPrice,
    Quantity,
    Title,
    Variant,
    Row,
    // SecondRow,
    RemoveItem,
    // Price,
    Thumb,
    ThumbImage,
} from './styles';

interface LineItemProps {
    item: LineItemInterface;
}

const LineItem = ({ item }: LineItemProps) => {
    const {
        removeLineItem,
        store: { client, checkout },
    } = useContext(StoreContext);

    const selectedOptions: string[] = [];
    if (item?.variant?.selectedOptions) {
        item.variant.selectedOptions.forEach((option: OptionInterface) => {
            if (option.name === 'Size' && option.value.toUpperCase() === 'CUSTOM') {
                selectedOptions.push('Made to measure');
            } else if (option.value !== 'Default Title') {
                selectedOptions.push(
                // `${option.name} ${option.value} `
                    `${option.value} `,
                );
            }
        });
    }

    const mapCustomAttributeMarkup = ({ key, value }: CustomAttributeInterface) => (
        <div key={key}>
            <Variant>
                {key}
            </Variant>
            {' '}
            {value}
            {/* {index < item.customAttributes.length - 1 && ', '} */}
        </div>
    );
    const customAttributes = item.customAttributes?.map(mapCustomAttributeMarkup);

    const handleRemove = () => {
        if (removeLineItem) {
            removeLineItem(client, checkout.id as string, item.id as string);
        }
    };

    const price = Intl.NumberFormat(undefined, {
        currency: item.variant?.priceV2.currencyCode ?? 'EUR',
        minimumFractionDigits: 0,
        style: 'currency',
    }).format(item.quantity * ((item.variant?.priceV2.amount || item.variant?.price) ?? 0));

    function getUrlExtension(url: string) {
        return url.split(/[#?]/)[0].split('.').pop()?.trim() || '';
    }

    function getImageThumbUrl(image: {
        src: string;
    }) {
        if (image) {
            const ext = getUrlExtension(image.src);
            const [imageUrl, v] = image.src?.split(`.${ext}`);
            const imageThumbUrl = `${imageUrl}_small.${ext}${v}`;

            return imageThumbUrl;
        }

        return undefined;
    }

    const image = item.variant?.image;
    const imageThumbUrl = image && getImageThumbUrl(image);
    const thumbSize = 100;

    return (
        <Wrapper>
            <Item>
                {/*
                    <ColumnQuantity>
                    {item.quantity}  ×
                    </ColumnQuantity>
                */}
                {image && (
                    <Thumb>
                        <ThumbImage
                            src={imageThumbUrl || image.src}
                            alt={`${item.title} product shot`}
                            width={thumbSize}
                            height={thumbSize}
                        />
                    </Thumb>
                )}
                <ColumnInformation>
                    <Row>
                        <Title>
                            <Quantity>
                                {item.quantity}
                                &nbsp;×&nbsp;
                            </Quantity>

                            {item.title}
                            {'  '}
                            {item.variant?.title !== 'Default Title' && (
                                item.variant?.title
                            )}
                        </Title>
                    </Row>
                    {selectedOptions?.length > 0 && (
                        <Row>
                            <Variant>
                                {selectedOptions}
                            </Variant>
                        </Row>
                    )}
                    {customAttributes && customAttributes?.length > 0 && (
                        <Row>
                            {customAttributes}
                        </Row>
                    )}
                    <Row>
                        <RemoveItem>
                            <Button onClick={handleRemove}>Remove</Button>
                        </RemoveItem>
                        {/*
                            <Price>
                            {price}
                            </Price>
                        */}
                    </Row>
                </ColumnInformation>
                <ColumnPrice>
                    {price}
                </ColumnPrice>
            </Item>
        </Wrapper>
    );
};

export default LineItem;
