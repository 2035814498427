import { ChangeEvent } from 'react';
import styled from '@emotion/styled';

interface CheckBoxProps {
    checked?: boolean | undefined;
    disabled?: boolean | undefined;
}

interface CheckBoxPropsInterface extends CheckBoxProps {
    id: string | undefined;
    name: string | undefined;
    onChange: (e: ChangeEvent) => void;
}

const CheckBoxWrap = styled.div(({ disabled }: CheckBoxProps) => (`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border: 2px solid;
    box-sizing: border-box;
    ${disabled && 'background: rgba(0, 0, 0, 0.1);'}
`));

const HiddenInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

const CheckMark = styled.svg`
    opacity: 0;
    scale: 0.8;
    transition: all 150ms ease-in-out;

    ${HiddenInput}:checked ~ & {
        opacity: 1;
        scale: 1;
    }
`;

function CheckBox({
    id, name, checked, disabled, onChange,
}: CheckBoxPropsInterface) {
    return (
        <CheckBoxWrap
            disabled={disabled}
        >
            <HiddenInput
                id={id}
                name={name}
                type="checkbox"
                disabled={disabled}
                defaultChecked={checked}
                onChange={onChange}
            />

            <CheckMark xmlns="http://www.w3.org/2000/svg" fill="none" width="35" height="35" viewBox="0 0 35 35">
                <path stroke="#000" strokeWidth="2" d="m7 16.12 8.2 8.19L28.5 11" />
            </CheckMark>
        </CheckBoxWrap>
    );
}

CheckBox.defaultProps = {
    checked: undefined,
    disabled: undefined,
};

export default CheckBox;
