import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { FC, ReactNode } from 'react';

const startsWith = (url: string, str: string) => (
    url?.indexOf(str) === 0
);

function LinkComponent(props: {
    to: string;
    target?: string;
    rel?: string;
    children: ReactNode | FC | FC[];
}) {
    const { to, children, target } = props;

    if (startsWith(to, 'http') || startsWith(to, 'mailto') || startsWith(to, 'tel')) {
        return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <a href={to} target={target} {...props}>{children}</a>
        );
    }

    return (
        <Link
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            {children}
        </Link>
    );
}

LinkComponent.defaultProps = {
    rel: undefined,
    target: undefined,
};

const StyledLink = styled(LinkComponent)`
    appearance: none;
    position: relative;
    display: inline;
    cursor: pointer;
    margin: 0;
    padding: 1px 0 0;
    line-height: 1;
    background: none;
    border: none;
    font-family: inherit;
    outline: none;
    text-transform: uppercase;
    font-size: inherit;
    color: inherit;
    text-decoration: none;

    &::after {
        position: absolute;
        left: 0;
        top: 100%;
        content: "";
        width: 100%;
        height: 2px;
        background: currentColor;
        transition: top 150ms ease-in-out;
        will-change: transform;
    }
`;

export default StyledLink;
